
import Vue, { PropType } from "vue"
import { mapStores } from "pinia"
import {
  Camera,
  TimelineGroupId,
  TimelineOverlayType,
  TimelineOverlayData,
} from "@evercam/shared/types"
import { useLayoutStore } from "@/stores/layout"
import {
  _360ProvidersLinks,
  _360ProvidersSuffixes,
} from "@evercam/shared/constants/360Providers"
import FrameViewer from "@/components/3dAppsDialogs/FrameViewer"
import MediaOverview from "@/components/mediaHub/MediaOverview"
import BimCompareContainer from "@/components/bimCompare/BimCompareContainer"
import CompareBox from "@/components/compare/CompareBox"
import { IngestApi } from "@evercam/shared/api/ingestApi"
import FrameViewerOverlay from "@/components/3dAppsDialogs/FrameViewerOverlay"

export default Vue.extend({
  name: "Timeline",
  components: {
    FrameViewer,
    MediaOverview,
    BimCompareContainer,
    CompareBox,
    FrameViewerOverlay,
  },
  props: {
    viewData: {
      type: Object as PropType<TimelineOverlayData<TimelineOverlayType>>,
      default: null,
    },
    viewType: {
      type: String as PropType<TimelineOverlayType>,
      required: true,
    },
    projectExid: {
      type: String,
      required: true,
    },
    height: {
      type: Number,
      required: true,
    },
    width: {
      type: Number,
      required: true,
    },
    selectedCamera: {
      type: Object as PropType<Camera>,
      default: undefined,
    },
    timezone: {
      type: String,
      default: "Europe/Dublin",
    },
    cameras: {
      type: Array as PropType<Camera[]>,
      default: () => [],
    },
  },
  data: () => ({
    mobileCapturePhotoURL: undefined,
    iframeData: null,
  }),
  computed: {
    ...mapStores(useLayoutStore),
    containerStyles(): Record<string, any> {
      return {
        height: `${this.height}px`,
        width: `${this.width}px`,
      }
    },
    overlayMaxHeight(): Record<string, any> {
      return {
        height: `${this.height}px`,
        maxHeight: `${this.height}px`,
      }
    },
    isMobileCapture(): boolean {
      return this.viewType === TimelineGroupId.MobileCapture
    },
    isBimCompare(): boolean {
      return this.viewType === TimelineGroupId.Bim
    },
    isMediaHub(): boolean {
      return this.viewType === TimelineGroupId.Media
    },
    isCompare(): boolean {
      return this.viewType === "compare"
    },
    isIframeView(): boolean {
      return this.is360View || this.isDroneView
    },
    iFrameUrl(): string {
      let url = ""
      if (this.is360View) {
        url = this._360Url
      } else if (this.isDroneView) {
        url = this.droneUrl
      }

      return url
    },
    iFrameQuery(): string {
      let query = ""
      if (this.is360View) {
        query = this._360Query
      } else if (this.isDroneView) {
        query = this.droneQuery
      }

      return query
    },
    is360View(): boolean {
      return this.viewType === TimelineGroupId.ThreeSixty
    },
    isDroneView(): boolean {
      return this.viewType === TimelineGroupId.Drone
    },
    _360Url(): string {
      return (
        this.$config.public.viewer360Url ||
        `https://${_360ProvidersLinks.Evercam}${_360ProvidersSuffixes.Evercam}`
      )
    },
    _360Query(): string {
      const viewData = this
        .viewData as TimelineOverlayData<TimelineGroupId.ThreeSixty>

      return `?project_id=${this.projectExid}&date_id=${viewData.dateID}&unit=metric&collapsible_toolbar=true&survey_toolbar_offset=50&bottom_toolbar_offset=${this.layoutStore.footerRect.height}&polarAngle=${viewData.polarAngle}&azimuthAngle=${viewData.azimuthAngle}`
    },
    droneUrl(): string {
      return this.$config.public.bimIframeUrl
    },
    droneQuery(): string {
      const droneModel = this
        .viewData as TimelineOverlayData<TimelineGroupId.Drone>

      return `3d?viewer=cesium&projectID=${this.projectExid}&type=site-view&unit=metric&user=client&date=${droneModel.date}&collapsible_toolbar=true&survey_toolbar_offset=50&bottom_toolbar_offset=${this.layoutStore.footerRect.height}`
    },
  },
  beforeDestroy() {
    this.destroyIframe()
    this.removeContainer()
    this.mobileCapturePhotoURL = undefined
  },
  mounted() {
    if (this.viewType === TimelineGroupId.MobileCapture) {
      this.fetchMobileCaptureUrl()
    }
  },
  methods: {
    destroyIframe() {
      const iFrameWrapperRef = this.$refs.iframe as Vue
      if (!iFrameWrapperRef) {
        return
      }

      const iframeElement = (
        iFrameWrapperRef.$el as HTMLElement
      )?.querySelector("iframe")
      if (!iframeElement) {
        return
      }

      iframeElement.remove()
    },
    removeContainer() {
      if (this.$refs?.container) {
        ;(this.$refs?.container as HTMLElement).remove()
      }
    },
    handleIframeMessage(data) {
      this.iframeData = data
    },
    async fetchMobileCaptureUrl() {
      const mobileCapture = this
        .viewData as TimelineOverlayData<TimelineGroupId.MobileCapture>
      this.mobileCapturePhotoURL =
        await IngestApi.mobileCapture.getMobileAssetPhoto(mobileCapture.id, {
          isThumbnail: false,
        })
    },
  },
})
