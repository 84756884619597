
import EvercamLoadingAnimation from "@evercam/shared/components/EvercamLoadingAnimation"
import { useRecordingsStore } from "@/stores/recordings"
import UploadImageToConnectors from "@/components/UploadImageToConnectors"
import { useAccountStore } from "@/stores/account"
import { useProjectStore } from "@/stores/project"
import { useCameraStore } from "@/stores/camera"
import { useMediaHubStore } from "@/stores/mediaHub"
import { useConnectorStore } from "@/stores/connector"
import { mapStores } from "pinia"
import { EvercamApi } from "@evercam/shared/api/evercamApi"
import { AnalyticsEvent, MediaType } from "@evercam/shared/types"
import { downloadFile } from "@evercam/shared/utils"

export default {
  name: "RecordingsXrayExportDialog",
  components: {
    EvercamLoadingAnimation,
    UploadImageToConnectors,
  },
  data() {
    return {
      dialog: false,
      isGeneratingImage: false,
      isDownloading: false,
      isSavingToMediahub: false,
    }
  },
  computed: {
    ...mapStores(
      useRecordingsStore,
      useAccountStore,
      useCameraStore,
      useMediaHubStore,
      useProjectStore,
      useConnectorStore
    ),
    exportedXrayImage() {
      return this.recordingsStore.exportedXrayImage
    },
    imageTitle() {
      const selectedTimestamp = this.$moment(
        this.recordingsStore.selectedTimestamp
      ).format("ddd, MMM Do YYYY, h:mm a")

      return `XRay: ${this.cameraStore.selectedCamera.name} ${selectedTimestamp}`
    },
  },
  watch: {
    dialog(visibilityStatus) {
      this.$analytics.saveEvent(AnalyticsEvent.XrayToggleExportDialog, {
        visible: visibilityStatus,
      })
    },
  },
  methods: {
    exportXray() {
      this.$root.$emit("toggle-playing")

      this.isGeneratingImage = true
      this.$root.$on("xray-image-generated", () => {
        this.isGeneratingImage = false
      })
      this.$root.$emit("generate-xray-image")
    },
    downloadExportedXrayImage() {
      this.$analytics.saveEvent(AnalyticsEvent.XrayDownload)
      this.isDownloading = true
      downloadFile(
        this.exportedXrayImage,
        `xray-${this.recordingsStore.selectedTimestamp}-${this.recordingsStore.selectedXrayTimestamp}.jpeg`
      )
      this.isDownloading = false
    },
    async saveToMediaHub() {
      this.$analytics.saveEvent(AnalyticsEvent.XraySaveToMediaHub)
      try {
        this.isSavingToMediahub = true
        const d = new Date(this.recordingsStore.selectedXrayTimestamp)
        const date = this.$moment(d).format("YYYY-MM-DDTHH:mm:ss.SSS") + "Z"
        const imageData = this.exportedXrayImage
        const payload = {
          title: `xray-${this.cameraStore.selectedCamera.name}-${this.recordingsStore.selectedTimestamp}`,
          type: MediaType.XRay,
          content: imageData,
          fileExtension: "jpeg",
          requestedBy: this.accountStore.email,
          cameraExid: this.cameraStore.selectedCamera?.id,
          fromDate: date,
          toDate: date,
        }
        await EvercamApi.mediaHub.cCreate(
          this.projectStore.selectedProject?.exid,
          payload
        )
        this.mediaHubStore.forceReloadMediaHub()
        this.$notifications.success(this.$t("content.media_hub.save_success"))
      } catch (error) {
        this.$notifications.error({
          text: this.$t("content.media_hub.save_failed"),
          error,
        })
      } finally {
        this.isSavingToMediahub = false
      }
    },
    closeDialog() {
      this.dialog = false
      this.isSavingToMediahub = false
    },
    saveUploadImageEvent(params) {
      this.$analytics.saveEvent(AnalyticsEvent.XraySendToConnector, params)
    },
  },
}
