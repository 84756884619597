
import Vue, { PropType } from "vue"
import ActionButton from "@evercam/shared/components/ActionButton"
import { TimelineGroupId } from "@evercam/shared/types"

export default Vue.extend({
  name: "TheTimelineActionButtons",
  components: {
    ActionButton,
  },
  props: {
    groupsVisibility: {
      type: Object as PropType<Record<TimelineGroupId, boolean>>,
      required: true,
    },
    disabledGroups: {
      type: Object as PropType<Record<TimelineGroupId, boolean>>,
      required: true,
    },
    hiddenGroups: {
      type: Array as PropType<Array<TimelineGroupId>>,
      required: true,
    },
    persistentGroups: {
      type: Array as PropType<Array<TimelineGroupId>>,
      required: true,
    },
  },
  data() {
    return {
      isHoveringMenu: false,
      labels: {
        [TimelineGroupId.ThreeSixty]: "360 Walks",
        [TimelineGroupId.Drone]: "Drone Flights",
        [TimelineGroupId.Bim]: "BIM Milestones",
        [TimelineGroupId.Media]: "Media Hub",
        [TimelineGroupId.Anpr]: "ANPR",
        [TimelineGroupId.MobileCapture]: "Mobile Capture",
        [TimelineGroupId.ExNvrRecordings]: "ExNVR Video",
        [TimelineGroupId.SiteActivity]: "Site Activity",
        [TimelineGroupId.SafetyReports]: "Safety Reports",
        [TimelineGroupId.ConstructionReports]: "Construction Reports",
        [TimelineGroupId.Comments]: "Comments",
        [TimelineGroupId.Luminance]: "Light changes",
      },
      svgIcons: {
        [TimelineGroupId.ThreeSixty]: "the360",
        [TimelineGroupId.Drone]: "drone-view",
        [TimelineGroupId.Bim]: "imodel",
      },
      icons: {
        [TimelineGroupId.Media]: "fas fa-box-archive",
        [TimelineGroupId.Anpr]: "fas fa-truck-moving",
        [TimelineGroupId.MobileCapture]: "fas fa-camera",
        [TimelineGroupId.ExNvrRecordings]: "fas fa-film",
        [TimelineGroupId.SiteActivity]: "fa-chart-line",
        [TimelineGroupId.SafetyReports]: "fa-helmet-safety",
        [TimelineGroupId.ConstructionReports]: "fa-person-digging",
        [TimelineGroupId.Comments]: "fa-regular fa-message",
        [TimelineGroupId.Luminance]: "fas fa-lightbulb",
      },
      svgIconColors: {
        disabled: "rgba(255, 255, 255, 0.3)",
        selected: "rgb(0, 49, 95)",
        default: "rgb(255, 255, 255)",
      },
      paddings: {
        [TimelineGroupId._360]: 8,
        [TimelineGroupId.Drone]: 11,
        [TimelineGroupId.Bim]: 8,
        [TimelineGroupId.Anpr]: 11,
      },
    }
  },
  computed: {
    groupVisibilityItems(): Array<{
      id: TimelineGroupId
      label: string
    }> {
      return Object.values(TimelineGroupId)
        .filter(
          (id) => ![...this.hiddenGroups, ...this.persistentGroups].includes(id)
        )
        .map((v) => ({
          id: v,
          label: this.labels[v],
          icon: this.icons[v],
          svgIcon: this.svgIcons[v],
        }))
    },
  },
  watch: {
    isHoveringMenu(v) {
      this.$emit("menu-hovered", v)
    },
  },
  methods: {
    toggleGroupVisibility(groupId: TimelineGroupId) {
      if (this.disabledGroups[groupId]) {
        return
      }
      this.onVisibilityChange(groupId, !this.groupsVisibility[groupId])
    },
    onVisibilityChange(groupId: TimelineGroupId, value: boolean) {
      this.$emit("visibility-change", { groupId, value })
    },
    getSvgIconColor(groupId: TimelineGroupId) {
      if (this.disabledGroups[groupId]) {
        return this.svgIconColors.disabled
      } else if (this.groupsVisibility[groupId]) {
        return this.svgIconColors.selected
      } else {
        return this.svgIconColors.default
      }
    },
    onButtonMouseOver() {
      this.isHoveringMenu = true
    },
    onButtonMouseLeave(e) {
      const toElement = e.toElement || e.relatedTarget
      if (
        toElement?.classList?.contains("action-btn") ||
        toElement?.classList?.contains("action-btn-container--disabled") ||
        toElement?.classList?.contains("v-btn__content") ||
        toElement?.classList?.contains("action-btn__text")
      ) {
        return
      }

      this.isHoveringMenu = false
    },
  },
})
